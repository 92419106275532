import React, { Suspense } from 'react';
import { generatePath, Link, Outlet } from 'react-router-dom';
import { Button, Card, ConfigProvider, Space, Spin } from 'antd';
import locale from 'antd/locale/lt_LT';
import { css } from '@emotion/css';

import { Session } from 'models/session';
import { createSession, deleteSession, getCurrentSession } from 'api/session';
import { handleError } from 'utils/handle-error';
import { AppContext } from 'app-context';
import { path } from 'constants/path';
import { mq } from 'constants/breakpoint';

export const App = () => {
  const [session, setSession] = React.useState<Session | null>(null);

  const handleLogin = React.useCallback(
    (phoneNumber: string, password: string) => {
      return createSession({
        phoneNumber,
        password,
      })
        .then(() => getCurrentSession())
        .then(setSession);
    },
    []
  );

  const handleLogout = React.useCallback(() => {
    return deleteSession()
      .then(() => getCurrentSession())
      .then(setSession);
  }, []);

  React.useEffect(() => {
    getCurrentSession().then(setSession).catch(handleError);
  }, []);

  if (!session) {
    return <Spin />;
  }

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: '#78faae',
          colorPrimaryBg: '#78faae',
          colorWhite: '#0e3a2f',
          colorLink: '#0e3a2f',
          colorLinkActive: '#0e3a2f',
          colorLinkHover: '#0e3a2f',
        },
      }}
    >
      <AppContext.Provider
        value={{ session, login: handleLogin, logout: handleLogout }}
      >
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            marginTop: 16,
            marginBottom: 16,
          })}
        >
          <Card
            className={css({
              width: '100%',
              [mq.md]: {
                width: 1024,
              },
            })}
          >
            <Space direction="vertical" className={css({ width: '100%' })}>
              {session?.user && (
                <div
                  className={css({
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                  })}
                >
                  <Link
                    to={generatePath(path.reservationPage)}
                    className={css({
                      'fontSize': 16,
                      'fontWeight': 'bold',
                      'padding': '14px 30px',
                      'backgroundColor': '#78faae',
                      'borderRadius': '22px',
                      ':hover': {
                        backgroundColor: '#a8ffcc',
                      },
                    })}
                  >
                    Rezervuoti
                  </Link>
                  <Link
                    to={generatePath(path.reservationsPage)}
                    className={css({
                      'fontSize': 16,
                      'fontWeight': 'bold',
                      'padding': '14px 30px',
                      'backgroundColor': '#78faae',
                      'borderRadius': '22px',
                      ':hover': {
                        backgroundColor: '#a8ffcc',
                      },
                    })}
                  >
                    Mano rezervacijos
                  </Link>
                  <div
                    className={css({
                      marginLeft: 'auto',
                      textAlign: 'right',
                    })}
                  >
                    <div>{session.user.name}</div>
                    <div>{session.user.phoneNumber}</div>
                  </div>
                  <Button
                    type="primary"
                    className={css({
                      'height': 'auto',
                      'fontSize': 16,
                      'fontWeight': 'bold',
                      'padding': '14px 30px',
                      'backgroundColor': '#78faae',
                      'borderRadius': '22px',
                      'marginLeft': 8,
                      ':hover': {
                        backgroundColor: '#a8ffcc',
                      },
                    })}
                    onClick={handleLogout}
                  >
                    Atsijungti
                  </Button>
                </div>
              )}
              {!session?.user && (
                <div
                  className={css({
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                  })}
                >
                  <Link
                    to={generatePath(path.reservationPage)}
                    className={css({
                      'fontSize': 16,
                      'fontWeight': 'bold',
                      'padding': '14px 30px',
                      'backgroundColor': '#78faae',
                      'borderRadius': '22px',
                      ':hover': {
                        backgroundColor: '#a8ffcc',
                      },
                    })}
                  >
                    Rezervuoti be registracijos
                  </Link>
                  <Link
                    to={generatePath(path.loginPage)}
                    className={css({
                      'marginLeft': 'auto',
                      'fontSize': 16,
                      'fontWeight': 'bold',
                      'padding': '14px 30px',
                      'backgroundColor': '#78faae',
                      'borderRadius': '22px',
                      ':hover': {
                        backgroundColor: '#a8ffcc',
                      },
                    })}
                  >
                    Prisijungti
                  </Link>
                  <Link
                    to={generatePath(path.registrationPage)}
                    className={css({
                      'fontSize': 16,
                      'fontWeight': 'bold',
                      'padding': '14px 30px',
                      'backgroundColor': '#78faae',
                      'borderRadius': '22px',
                      ':hover': {
                        backgroundColor: '#a8ffcc',
                      },
                    })}
                  >
                    Registruotis
                  </Link>
                </div>
              )}
              <Suspense fallback={<Spin />}>
                <Outlet />
              </Suspense>
            </Space>
          </Card>
        </div>
      </AppContext.Provider>
    </ConfigProvider>
  );
};
